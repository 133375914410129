// Tools
import Vue from "vue";
import VueRouter from "vue-router";

// Role List
import {
  apiLsClientVariable,
  apiLsVariable,
  getUserData,
  hasRoles,
  roles,
} from "@/utils/utils";

// Layouts
import AdminLayout from "@/layouts/AdminLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import store from "@/store";
import * as actions from "@/utils/actions";
import localStorageService from "@/utils/localStorage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "",
        name: "home",
        exact: true,
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/client/Home.vue"),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/shorturl/:uuid",
        name: "shorturl",
        exact: true,
        component: () =>
          import(/* webpackChunkName: "shorturl" */ "@/views/client/Home.vue"),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/results",
        name: "results",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "results" */ "@/views/client/Results.vue"
          ),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/results/:lottery",
        name: "lottery-results",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "lottery-results" */ "@/views/client/Results.vue"
          ),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/how-to-play",
        name: "how-to-play",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "how-to-play" */ "@/views/client/HowToPlay.vue"
          ),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/problem-report",
        name: "problem-report",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "problem-report" */ "@/views/client/ProblemReport.vue"
          ),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/login",
        name: "login",
        exact: true,
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/client/Login.vue"),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/register",
        name: "register",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "register" */ "@/views/client/Register.vue"
          ),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/welcome",
        name: "welcome",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "welcome" */ "@/views/client/Welcome.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "password-reset-page" */ "@/views/client/PasswordReset.vue"
          ),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/buy",
        name: "buy",
        exact: true,
        component: () =>
          import(/* webpackChunkName: "buy-page" */ "@/views/client/Buy.vue"),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/change-password",
        name: "change-password",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "change-password-page" */ "@/views/client/ChangePassword.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/referrals",
        name: "referrals",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "referrals-page" */ "@/views/client/Referral.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/verify-phone",
        name: "verify-phone",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "ticket-list-page" */ "@/views/client/register/VerifyPhone.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
        beforeEnter(to, from, next) {
          // TODO: Add whatsapp_verified to response/store
          if (store.state.clientAuth?.user?.whatsapp_verified) {
            next({ name: "welcome" });
          } else {
            next();
          }
        },
      },
      {
        path: "/ticket-list",
        name: "ticket-list",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "ticket-list-page" */ "@/views/client/tickets/TicketList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/ticket/:code",
        name: "ticket-view",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "ticket-view-page" */ "@/views/client/tickets/TicketView.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/transaction-list",
        name: "transaction-list",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "transaction-list-page" */ "@/views/client/transactions/TransactionList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/transaction/request",
        name: "transaction-request",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "transaction-request-page" */ "@/views/client/transactions/TransactionForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/transaction/:code",
        name: "transaction-view",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "transaction-view-page" */ "@/views/client/transactions/TransactionView.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/balance-movements",
        name: "balance-movements",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "balance-movements-page" */ "@/views/client/UserBalanceMovementsList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/cash-balance-report",
        name: "cash-balance-report",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "cash-balance-report-page" */ "@/views/client/UserCashBalanceReport.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
      {
        path: "/privacy-policies",
        name: "privacy-policies",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "privacy-policies-page" */ "@/views/client/PrivacyPolicies.vue"
          ),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/terms-and-conditions",
        name: "terms-and-conditions",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "terms-and-conditions-page" */ "@/views/client/TermsAndConditions.vue"
          ),
        meta: {
          requiresAuth: false,
          module: "user",
        },
      },
      {
        path: "/notifications",
        name: "notifications",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "notifications-page" */ "@/views/client/UserNotificationsList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "user",
        },
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    exact: true,
    component: () =>
      import(/* webpackChunkName: "admin-page" */ "@/views/admin/Login.vue"),
    meta: {
      requiresAuth: false,
      module: "admin",
    },
    beforeEnter(to, from, next) {
      if (store.state.adminAuth.authenticated) {
        next({ name: "admin.dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/admin/password-reset",
    name: "admin.password.reset",
    exact: true,
    component: () =>
      import(
        /* webpackChunkName: "admin-password-reset-page" */ "@/views/admin/PasswordReset.vue"
      ),
    meta: {
      requiresAuth: false,
      module: "admin",
    },
    beforeEnter(to, from, next) {
      if (store.state.adminAuth.authenticated) {
        next({ name: "admin.dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/admin",
    component: AdminLayout,
    children: [
      {
        path: "/admin/dashboard",
        name: "admin.dashboard",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-dashboard-page" */ "@/views/admin/Dashboard.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_USER],
        },
      },
      {
        path: "/admin/reports/monitoring",
        name: "admin.reports.monitoring",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-reports-monitoring-page" */ "@/views/admin/reports/MonitoringReport.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_MONITORING_REPORT,
          ],
        },
      },
      {
        path: "/admin/admins",
        name: "admin.admins",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-admins-page" */ "@/views/admin/admins/AdminList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_ADMINS],
        },
      },
      {
        path: "/admin/admins/create",
        name: "admin.admins.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-admins-form-page" */ "@/views/admin/admins/AdminForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_ADMINS_CREATE,
          ],
        },
      },
      {
        path: "/admin/admins/:id",
        name: "admin.admins.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-admins-form-page" */ "@/views/admin/admins/AdminForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_ADMINS,
            roles.admin.ROLE_ADMINS_UPDATE,
          ],
        },
      },
      {
        path: "/admin/users",
        name: "admin.users",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-users-page" */ "@/views/admin/users/UserList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_USERS],
        },
      },
      {
        path: "/admin/users/:id",
        name: "admin.users.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-users-form-page" */ "@/views/admin/users/UserForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USERS,
            roles.admin.ROLE_USERS_UPDATE,
          ],
        },
      },
      {
        path: "/admin/lotteries",
        name: "admin.lotteries",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-page" */ "@/views/admin/lotteries/LotteryList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_LOTTERIES],
        },
      },
      {
        path: "/admin/lotteries/create",
        name: "admin.lotteries.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-form-page" */ "@/views/admin/lotteries/LotteryForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES_CREATE,
          ],
        },
      },
      {
        path: "/admin/lotteries/:id",
        name: "admin.lotteries.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-form-page" */ "@/views/admin/lotteries/LotteryForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES,
            roles.admin.ROLE_LOTTERIES_UPDATE,
          ],
        },
      },
      {
        path: "/admin/lotteries-closing-schedules",
        name: "admin.lotteries-closing-schedules",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-closing-schedules-page" */ "@/views/admin/lotteries-schedules/ScheduleList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE,
          ],
        },
      },
      {
        path: "/admin/lotteries-closing-schedules/create",
        name: "admin.lotteries-closing-schedules.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-closing-schedules-create-page" */ "@/views/admin/lotteries-schedules/ScheduleForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE,
          ],
        },
      },
      {
        path: "/admin/lotteries-closing-schedules/:id",
        name: "admin.lotteries-closing-schedules.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-lotteries-closing-schedules-edit-page" */ "@/views/admin/lotteries-schedules/ScheduleForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_LOTTERIES_CLOSING_SCHEDULE,
          ],
        },
      },
      {
        path: "/admin/raffles",
        name: "admin.raffles",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-raffles-page" */ "@/views/admin/raffles/RaffleList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_RAFFLES],
        },
      },
      {
        path: "/admin/raffles/create",
        name: "admin.raffles.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-raffles-form-page" */ "@/views/admin/raffles/RaffleForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_RAFFLES_SAVE],
        },
      },
      {
        path: "/admin/raffles/:date",
        name: "admin.raffles.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-raffles-form-page" */ "@/views/admin/raffles/RaffleForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_RAFFLES,
            roles.admin.ROLE_RAFFLES_SAVE,
          ],
        },
      },
      {
        path: "/admin/blocks",
        name: "admin.blocks",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-blocks-page" */ "@/views/admin/blocks/BlockList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_BLOCKS],
        },
      },
      {
        path: "/admin/blocks/create",
        name: "admin.blocks.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-blocks-form-page" */ "@/views/admin/blocks/BlockForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_BLOCKS_CREATE,
          ],
        },
      },
      {
        path: "/admin/blocks/:id",
        name: "admin.blocks.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-blocks-form-page" */ "@/views/admin/blocks/BlockForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_BLOCKS,
            roles.admin.ROLE_BLOCKS_UPDATE,
          ],
        },
      },
      {
        path: "/admin/promotions",
        name: "admin.promotions",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-promotions-page" */ "@/views/admin/promotions/PromotionList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_PROMOTIONS],
        },
      },
      {
        path: "/admin/promotions/create",
        name: "admin.promotions.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-promotions-form-page" */ "@/views/admin/promotions/PromotionForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_PROMOTIONS_CREATE,
          ],
        },
      },
      {
        path: "/admin/promotions/:id",
        name: "admin.promotions.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-promotions-form-page" */ "@/views/admin/promotions/PromotionForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_PROMOTIONS,
            roles.admin.ROLE_PROMOTIONS_UPDATE,
          ],
        },
      },
      {
        path: "/admin/transactions",
        name: "admin.transactions",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transactions-page" */ "@/views/admin/transactions/TransactionList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_TRANSACTIONS],
        },
      },
      {
        path: "/admin/transactions/process",
        name: "admin.transactions.process",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-process-page" */ "@/views/admin/transactions/TransactionForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_TRANSACTIONS_PROCESS,
          ],
        },
      },
      {
        path: "/admin/transactions/:id/view",
        name: "admin.transactions.view",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-view-page" */ "@/views/admin/transactions/TransactionForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_TRANSACTIONS],
        },
      },
      {
        path: "/admin/balance-movements",
        name: "admin.balance-movements",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-balance-movements-page" */ "@/views/admin/balance-movements/UserBalanceMovementsList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_BALANCE_MOVEMENTS,
          ],
        },
      },
      {
        path: "/admin/commissions",
        name: "admin.commissions",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-commissions-page" */ "@/views/admin/commissions/Report.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_COMMISSIONS_GENERATE,
          ],
        },
      },
      {
        path: "/admin/orders",
        name: "admin.orders",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-orders-page" */ "@/views/admin/orders/OrderList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_ORDERS],
        },
      },
      {
        path: "/admin/orders/:id",
        name: "admin.orders.view",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-orders-view-page" */ "@/views/admin/orders/OrderView.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN, roles.admin.ROLE_ORDERS],
        },
      },
      {
        path: "/admin/system-config",
        name: "admin.system-config",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-system-config-page" */ "@/views/admin/config/SystemConfig.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [roles.admin.ROLE_ADMIN],
        },
      },
      {
        path: "/admin/commission-profiles",
        name: "admin.commission-profiles",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-commission-profiles-page" */ "@/views/admin/commission-profiles/CommissionProfileList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_COMMISSION_PROFILES,
          ],
        },
      },
      {
        path: "/admin/commission-profiles/create",
        name: "admin.commission-profiles.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-commission-profiles-form-page" */ "@/views/admin/commission-profiles/CommissionProfileForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_COMMISSION_PROFILES_CREATE,
          ],
        },
      },
      {
        path: "/admin/commission-profiles/:id",
        name: "admin.commission-profiles.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-commission-profiles-form-page" */ "@/views/admin/commission-profiles/CommissionProfileForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_COMMISSION_PROFILES,
            roles.admin.ROLE_USER_COMMISSION_PROFILES_UPDATE,
          ],
        },
      },
      {
        path: "/admin/transaction-profiles",
        name: "admin.transaction-profiles",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-profiles-page" */ "@/views/admin/transaction-profiles/TransactionProfileList.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_TRANSACTION_PROFILES,
          ],
        },
      },
      {
        path: "/admin/transaction-profiles/create",
        name: "admin.transaction-profiles.create",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-profiles-form-page" */ "@/views/admin/transaction-profiles/TransactionProfileForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_TRANSACTION_PROFILES_CREATE,
          ],
        },
      },
      {
        path: "/admin/transaction-profiles/:id",
        name: "admin.transaction-profiles.edit",
        exact: true,
        component: () =>
          import(
            /* webpackChunkName: "admin-transaction-profiles-form-page" */ "@/views/admin/transaction-profiles/TransactionProfileForm.vue"
          ),
        meta: {
          requiresAuth: true,
          module: "admin",
          allowedRoles: [
            roles.admin.ROLE_ADMIN,
            roles.admin.ROLE_USER_TRANSACTION_PROFILES,
            roles.admin.ROLE_USER_TRANSACTION_PROFILES_UPDATE,
          ],
        },
      },
    ],
  },
  {
    path: "/verify-account/:code",
    name: "verify-account",
    exact: true,
    component: () =>
      import(
        /* webpackChunkName: "verify-account-page" */ "@/views/client/AccountVerification.vue"
      ),
    meta: {
      requiresAuth: false,
      module: "user",
    },
  },
  {
    path: "/password-reset/:code",
    name: "password-reset-verification",
    exact: true,
    component: () =>
      import(
        /* webpackChunkName: "password-reset-verification-page" */ "@/views/client/PasswordResetVerification.vue"
      ),
    meta: {
      requiresAuth: false,
      module: "user",
    },
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // Function to scroll to top on change page
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const isUserRoute = to.meta?.module === "user";
    let session = localStorageService.get(
      isUserRoute ? apiLsClientVariable : apiLsVariable
    );
    if (!session) {
      const isBuyPage = to.name === "buy" && to.query?.shared;
      store.dispatch(`${isUserRoute ? "client" : "admin"}Auth/handleLogout`);
      if (isBuyPage) {
        store.state.redirectAfterAll = to.fullPath;
        store.dispatch(`shortUrl/fetchShortUrl`, to.query.shared);
        return;
      } else {
        store.state.redirectRoute = to.name;
        next({
          name: isUserRoute ? "login" : "admin",
        });
      }
    } else {
      session = session ? JSON.parse(window.atob(session)) : null;
      const userData =
        store.state[isUserRoute ? "clientAuth" : "adminAuth"].user ||
        getUserData(session.token);
      if (to.meta.allowedRoles && to.meta.allowedRoles.length > 0) {
        const userRoles = userData?.accessRoles || [];
        const allowedRoles = to.meta.allowedRoles;
        if (!hasRoles(userRoles, allowedRoles)) {
          store.state.redirectRoute = null;
          next({
            name: isUserRoute ? "login" : "admin",
          });
        } else if (store?.state?.redirectRoute) {
          const redirectRoute = store.state.redirectRoute;
          store.state.redirectRoute = null;
          if (redirectRoute.includes("/")) {
            next(redirectRoute);
          } else {
            next({ name: redirectRoute });
          }
        }
      }

      store.commit(
        `${isUserRoute ? "clientAuth" : "adminAuth"}/${
          isUserRoute ? actions.CLIENT_LOGIN : actions.ADMIN_LOGIN
        }`,
        {
          user: userData,
          token: session.token,
          expires_in: session.expires_in,
          authenticated: !!userData,
        }
      );
    }
  }
  next();
});

export default router;
